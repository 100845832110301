<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">EstImage</p>
            </div>
            <div class="column is-narrow">
              <div class="dropdown is-right is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu-pnet">
                    <span>EstImage</span>
                    <span class="icon is-small">
                      <i class="mdi mdi-chevron-down"
                        aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu"
                  id="dropdown-menu-pnet"
                  role="menu">
                  <div class="dropdown-content">
                    <!-- <a href="#" -->
                    <a v-if="isSubmitQuoteEnabled"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="openSendQuoteModal()">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-upload mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Quote</span>
                    </a>
                    <a v-if="isSubmitInvoiceEnabled"
                      href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="openSendInvoiceModal()">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-currency-usd mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Submit Tax Invoice</span>
                    </a>
                    <a href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="updateVehicle()">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-car mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Update Vehicle Details</span>
                    </a>
                    <a href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="openUploadImageModal()">
                      <span class="icon is-small is-left has-text-primary">
                        <i class="mdi mdi-image-multiple mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Upload images</span>
                    </a>
                    <hr class="dropdown-divider">
                    <a href="#"
                      class="dropdown-item dropdown-item-has-icon"
                      @click="viewMessages()">
                      <span class="icon is-small is-left has-text-primary"
                        style="self-align: center">
                        <i class="mdi mdi-file-code mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">View Messages</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="tile is-child box detail-page-tile">
          <p class="title">Remarks</p>
          <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="filter.pageIndex"
            :page-size="filter.pageSize"
            :total-rows="remarkTotalRows"
            :is-loading="isTableLoading"
            :sort-column="filter.sortColumn"
            :sort-order="filter.sortOrder"
            :show-header="false"
            @pageChanged="onPageChange"
            @sort="sort">
            <tr v-for="(remark, index) in pagedRemarks"
              :key="remark.remarkId"
              @click="highlightSelected(index, $event)">
              <td class="is-content-width">
                <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
              </td>
              <td class="is-remark">
                <div>{{ remark.remarks }}</div>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </article>
      </div>
    </div>
    <est-image-send-quote-modal v-if="isSendQuoteModalActive"
      :active.sync="isSendQuoteModalActive"
      :value="innerValue"
      :modal-type="modalType"
      :title="modalTitle"
      :icon-name="'mdi-upload'"
      @close="closeModal"
      @submit="submit" />
    <xml-viewer-modal v-if="isXmlViewerModalActive"
      :active.sync="isXmlViewerModalActive"
      :quote-id="value.quoteId"
      :message-type="messageType"
      :page-size="10"
      @close="isXmlViewerModalActive = false">
      <p slot="text-title">View {{ messageType }} Messages</p>
    </xml-viewer-modal>
  </div>
</template>

<script>
import { RemarkTypes, EventHubTypes, InvoiceTypes } from '@/enums'
import { QuoteNotificationMixin } from './mixins'
import QuoteStoreMixin from './mixins/QuoteStoreMixin'
import { RemarkColumns } from './columns'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import _cloneDeep from 'lodash/cloneDeep'
import { EstImageService } from '@/services'
import EstImageSendQuoteModal from './components/EstImageSendQuoteModal.vue'
import { EstImageSendQuoteModel } from '@/classes'
import { XmlViewerModal } from '@/components/XmlViewer'

export default {
  name: 'QuoteEstImage',
  inject: ['$vv'],
  components: {
    BulmaTable,
    EstImageSendQuoteModal,
    XmlViewerModal
  },
  filters: {},
  mixins: [QuoteNotificationMixin, QuoteStoreMixin, DateTimeFiltersMixin],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: null,
      filter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 8
      },
      isTableLoading: false,
      isSendQuoteModalActive: false,
      isUploadImage: false,
      isXmlViewerModalActive: false,
      modalTitle: '',
      modalIcon: '',
      selectedImageIds: [],
      modalType: '',
      messageType: 'EstImage'
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    columns() {
      return RemarkColumns
    },
    remarkTotalRows() {
      return this.value.otherRemarks.filter((r) => r.remarkTypeId === RemarkTypes.EstImageSystemRemark && !r.isDeleted).length
    },
    activeRemarks() {
      return this.value.otherRemarks.filter((r) => r.remarkTypeId === RemarkTypes.EstImageSystemRemark && !r.isDeleted)
    },
    pagedRemarks() {
      return this.activeRemarks.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    },
    isSubmitQuoteEnabled() {
      return !this.value.readOnly && !this.value.isAssessmentEnabled
    },
    isSubmitInvoiceEnabled() {
      return !this.value.readOnly && this.value.invoices.some((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted)
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    //this.innerValue = _cloneDeep(this.value)
    this.$emit('save', false, false)
    this.loading = true
    this.innerValue = _cloneDeep(this.value)
    this.loading = false
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    openUploadImageModal() {
      this.modalType = 'ImageSelect'
      this.isSendQuoteModalActive = true
      this.modalTitle = 'Upload images'
      this.modalIcon = 'mdi-image-multiple'
    },
    openSendQuoteModal() {
      this.modalType = 'SendQuote'
      this.isSendQuoteModalActive = true
      this.modalTitle = 'Send Quote'
      this.modalIcon = 'mdi-upload'
    },
    openUpdateVehicleModal() {
      this.modalType = 'UpdateVehicle'
      this.isSendQuoteModalActive = true
      this.modalTitle = 'Update Vehicle'
      this.modalIcon = 'mdi-car'
    },
    openSendInvoiceModal() {
      this.modalType = 'SendInvoice'
      this.isSendQuoteModalActive = true
      this.modalTitle = 'Send Invoice'
      this.modalIcon = 'mdi-currency-usd'
    },
    closeModal() {
      this.isSendQuoteModalActive = false
      this.modalType = ''
      this.modalTitle = ''
      this.modalIcon = ''
    },
    viewMessages() {
      this.isXmlViewerModalActive = true
    },
    async updateVehicle() {
      this.$showSpinner('Submitting')
      let sendQuoteObject = new EstImageSendQuoteModel()
      sendQuoteObject.quoteId = this.value.quoteId
      await EstImageService.updateVehicle(sendQuoteObject)
      this.showSuccessToast('Vehicle details submitted.')
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.$hideSpinner()
    },
    async submit(sendObject) {
      this.$showSpinner('Submitting')
      sendObject.quoteId = this.innerValue.quoteId
      try {
        if (this.modalType === 'ImageSelect') {
          await EstImageService.uploadImages(sendObject)
          this.showSuccessToast('Image(s) submitted.')
        } else if (this.modalType === 'SendQuote') {
          var response = await EstImageService.sendQuote(sendObject)
          if (response?.data?.error) {
            this.$notification.openNotificationWithType('danger', 'Error on Sending Quote to EstImage', response?.data?.details)
          } else {
            this.showSuccessToast('Quote details submitted.')
          }
        } else if (this.modalType === 'SendInvoice') {
          await EstImageService.sendInvoice(sendObject)
          this.showSuccessToast('Invoice details submitted.')
        } else {
          this.$hideSpinner()
          this.closeModal()
          return
        }
      } catch (e) {
        this.$hideSpinner()
        this.closeModal()
      } finally {
        this.$eventHub.$emit(EventHubTypes.EntityReload)
        this.$hideSpinner()
        this.closeModal()
      }
      // if (this.modalType === 'ImageSelect') {
      //   await EstImageService.uploadImages(sendObject)
      //   this.showSuccessToast('Image(s) submitted.')
      // } else if (this.modalType === 'SendQuote') {
      //   await EstImageService.sendQuote(sendObject)
      //   this.showSuccessToast('Quote details submitted.')
      // } else if (this.modalType === 'SendInvoice') {
      //   await EstImageService.sendInvoice(sendObject)
      //   this.showSuccessToast('Invoice details submitted.')
      // } else {
      //   this.$hideSpinner()
      //   this.closeModal()
      //   return
      // }
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
      // this.$hideSpinner()
      // this.closeModal()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    sort(name, order) {}
  }
}
</script>

<style lang="scss" scoped>
a.dropdown-item-has-icon {
  display: flex;
  align-items: center;
}
span.dropdown-item-text {
  padding-left: 5px;
}
</style>
